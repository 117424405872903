<template>
    <div class="system-roles list-page page">
        <div class="page--list">
            <div class="scroll-wrapper pd4 set--bottom-foot-padding">
                <el-row
                    style="flex-wrap: wrap; padding: 16px 16px 0 16px; background: #f4f7fa; margin-bottom: 15px"
                    class="flex"
                >
                    <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" style="margin-bottom: 16px">
                        <div>
                            <el-input placeholder="请输入内容" v-model.trim="search_value" class="input-with-select">
                                <el-select
                                    style="width: 120px"
                                    v-model="search_type"
                                    slot="prepend"
                                    placeholder="请选择"
                                >
                                    <el-option
                                        v-for="item in searchTypeEnum"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                            </el-input>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="8" :xl="8" style="margin-bottom: 16px; margin-left: 10px">
                        <div class="flex-wrap a-center" style="margin-right: 32px; margin-bottom: 16px">
                            <div style="flex-shrink: 0">是否推荐：</div>
                            <el-select
                                class="width2"
                                v-model="searchIsRecommend"
                                clearable
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option label="是" :value="1"></el-option>
                                <el-option label="否" :value="0"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="6" style="margin-bottom: 16px">
                        <div class="flex-wrap a-center" style="margin-right: 32px; margin-bottom: 16px">
                            <div style="flex-shrink: 0">推荐状态：</div>
                            <el-select
                                class="width2"
                                v-model.trim="searchState"
                                clearable
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option label="成功" :value="1"></el-option>
                                <el-option label="失败" :value="2"></el-option>
                                <el-option label="异常" :value="3"></el-option>
                            </el-select>
                        </div>
                    </el-col>
                    <el-col :xs="24" :sm="24" :md="24" :lg="14" :xl="12" style="margin-bottom: 16px">
                        <div style="margin-bottom: 16px; margin-right: 32px">
                            <el-select
                                class="width1h"
                                style="width: 120px; margin-right: 8px"
                                @change="changetimetype"
                                v-model="searchTimeType"
                                slot="prepend"
                                placeholder="请选择"
                            >
                                <el-option
                                    v-for="item in searchTypeEnum2"
                                    :key="item.value"
                                    :label="item.name"
                                    :value="item.value"
                                ></el-option>
                            </el-select>
                            <el-date-picker
                                :type="datetype"
                                class="width2"
                                v-model="searchStartTime"
                                :value-format="valueformat"
                                placeholder="请选择"
                            ></el-date-picker>
                            <span> - </span>
                            <el-date-picker
                                :type="datetype"
                                class="width1h"
                                v-model="searchEndTime"
                                :value-format="valueformat"
                                placeholder="请选择"
                            ></el-date-picker>
                        </div>
                    </el-col>

                    <el-col :span="10" style="margin-bottom: 16px">
                        <el-button
                            style="margin-left: 24px; margin-bottom: 16px"
                            type="primary"
                            @click.stop="handleSubmit"
                            >搜索</el-button
                        >
                        <el-button @click.stop="handleClear">清空</el-button>
                    </el-col>
                </el-row>
                <el-table :data="tableData">
                    <el-table-column type="selection" width="55"> </el-table-column>
                    <el-table-column label="客户编号" prop="custCode"></el-table-column>
                    <el-table-column label="客户姓名" prop="NAME" min-width="160"></el-table-column>
                    <el-table-column label="手机号" prop="phone"></el-table-column>
                    <el-table-column label="是否推荐" prop="isSellName"> </el-table-column>
                    <el-table-column label="推荐时间" prop="sellTimeName"> </el-table-column>
                    <el-table-column label="推荐状态" prop="stateName"> </el-table-column>
                    <el-table-column label="录入渠道商名称" prop="chanName"> </el-table-column>
                    <el-table-column label="录入时间" prop="createTimeName"> </el-table-column>
                </el-table>
            </div>

            <list-foot-bar>
                <el-pagination
                    layout="total, sizes, prev, pager, next, jumper"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    :total="total"
                    @size-change="handlePageSizeChange"
                    @current-change="handlePageNoChange"
                ></el-pagination>
            </list-foot-bar>
        </div>
    </div>
</template>

<script>
    export default {
        components: {},
        props: {},
        data() {
            return {
                tableData: [],
                search_value: '',

                search_type: 1,
                searchTimeType: 1,
                searchStartTime: '',
                searchEndTime: '',
                searchTypeEnum2: [
                    { name: '录入日期', value: 1 },
                    { name: '推荐日期', value: 2 },
                ],
                valueformat: 'yyyy-MM-dd',
                datetype: 'date',
                searchIsRecommend: undefined,
                searchState: undefined,
                pageNum: 1,
                pageSize: 10,
                total: 0,

                searchTypeEnum: [
                    { name: '渠道商名称', value: 1 },
                    { name: '客户编号', value: 2 },
                    { name: '手机号', value: 3 },
                ],

                loading: false,
            };
        },
        created() {
            this.fetchData();
        },
        mounted() {},
        watch: {},
        computed: {},
        methods: {
            changetimetype(data) {
                console.log(data);
                this.searchStartTime = '';
                this.searchEndTime = '';
                if (data == 2) {
                    this.valueformat = 'yyyy-MM';
                    this.datetype = 'month';
                } else {
                    this.valueformat = 'yyyy-MM-dd';
                    this.datetype = 'date';
                }
            },
            handleSubmit() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.fetchData();
            },
            handleClear() {
                this.pageNum = 1;
                this.pageSize = 10;
                this.search_value = '';

                this.search_type = 1;
                this.searchTimeType = 1;
                this.searchStartTime = '';
                this.searchEndTime = '';
                this.searchIsRecommend = undefined;
                this.searchState = undefined;

                this.fetchData();
            },
            async fetchData() {
                const loading = this.$loading();
                const res = await this.$axios({
                    url: '/api/channel/queryChannelCustLogList',
                    method: 'post',
                    data: {
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        searchType: this.search_type,
                        searchValue: this.search_value,
                        searchIsRecommend: this.searchIsRecommend,
                        searchState: this.searchState,
                        searchIsDeal: this.searchIsDeal,
                        searchTimeType: this.searchTimeType,
                        searchStartTime: this.searchStartTime,
                        searchEndTime: this.searchEndTime,
                    },
                });
                loading.close();
                if (res.code === 2000) {
                    this.tableData = res.data.custLogList || [];
                    this.total = res.data.count || 0;
                } else {
                    this.$message({
                        message: res.msg || '获取数据失败',
                        type: 'warning',
                    });
                }
            },
            // 翻页相关
            handlePageSizeChange(size) {
                this.pageSize = size;
                this.fetchData();
            },
            // 翻页相关
            handlePageNoChange(no) {
                this.pageNum = no;
                this.fetchData();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .empidselect {
        width: 340px;
    }
</style>
